<template>
  <v-container v-if="isRouterAlive">
    <v-dialog v-model="dialog" hide-overlay persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Conectando a pasarela de pago Flow... por favor espere
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Equipos</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row class="mt-4">
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-card-subtitle>
                <v-row class="">
                  <v-col cols="12" md="1">
                    <v-btn class="ma-2" align="center" outlined x-large fab color="primary">
                      <v-icon color="primary">{{
                        "mdi-desktop-classic"
                      }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="7">
                    <p class="mt-7 text-left">
                      Listado de equipos disponibles y precios:
                    </p>
                  </v-col>
                  <v-col cols="12" md="4" />
                </v-row>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, i) in items" :key="i" cols="12" md="4">
            <div v-if="item.price > 0">
              <v-card v-if="item.price > 0" class="mx-auto" height="100%">
                <br v-if="item.price > 0" />
                <v-btn v-if="item.previous_price > 0" style="position: absolute; z-index: 10;" tile color="error"
                  depressed>
                  {{
                    "-" +
                    Math.trunc(100 - (item.price * 100) / item.previous_price) +
                    " %"
                  }}
                </v-btn>
                <v-img v-if="item.price > 0" aspect-ratio="1.7" contain
                  :src="'https://api.sipymex.cl/' + item.image"></v-img>
                <v-card-title v-if="item.price > 0">
                  <h5>
                    <p>{{ item.name }}</p>
                  </h5>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-actions v-if="item.price > 0">
                  <v-btn color="#213b7c" text @click="show = !show">
                    <v-icon v-if="item.price > 0" right dark> mdi-eye </v-icon>
                    Ver detalles
                  </v-btn>
                  <v-spacer v-if="item.price > 0"></v-spacer>
                  <v-btn v-if="item.price > 0" icon @click="show = !show">
                    <v-icon v-if="item.price > 0">{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-divider v-if="item.price > 0"></v-divider>
                <v-card-actions v-if="item.price > 0">
                  <div v-if="item.price > 0" class="font-weight-black">
                    <strong style="color=#213b7c">{{ item.price }} CLP</strong>
                  </div>
                  <v-spacer v-if="item.price > 0"></v-spacer>
                  <div v-if="item.price > 0" class="text-decoration-line-through">
                    {{
                      item.previous_price == 0
                      ? ""
                      : item.previous_price + " CLP"
                    }}
                  </div>
                  <v-spacer v-if="item.price > 0"></v-spacer>
                  <v-spacer v-if="item.price > 0"></v-spacer>
                  <v-spacer v-if="item.price > 0"></v-spacer>
                  <v-spacer v-if="item.price > 0"></v-spacer>
                  <v-btn v-if="item.price > 0" text color="#213b7c" @click="payment(item)">
                    <v-icon v-if="item.price > 0" right dark> mdi-cart </v-icon>
                    Comprar
                  </v-btn>
                </v-card-actions>
                <v-expand-transition v-if="item.price > 0">
                  <div v-if="item.price > 0" v-show="show">
                    <v-divider v-if="item.price > 0"></v-divider>
                    <v-card-text v-if="item.price > 0" class="text-justify">
                      {{ item.description }}
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" md="2"></v-col>
        </v-row>
        <v-row class="mt-1"></v-row>
      </v-card-text>
      <p class="text-h2 font-weight-bold text-center pt-10 pb-10" style="color: #179db0">
        Próximamente
      </p>
      <template>
        <v-card elevation="0" class="mx-auto">
          <v-container>
            <v-row dense>
              <v-col v-for="(item, i) in items_promo" :key="i" cols="12" md="4">
                <v-card v-if="i == 0 || i % 3 == 0" color="#0e73b1" dark height="100%">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title class="text-h5" v-text="item.name"></v-card-title>
                      <v-card-subtitle v-text="item.description"></v-card-subtitle>
                      <v-card-actions> </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="125" tile>
                      <v-img v-if="i == 0 || i % 3 == 0" :src="'https://api.sipymex.cl/' + item.image"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
                <v-card v-if="i == 1 || i % 3 == 1" color="#e9501c" dark height="100%">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title class="text-h5" v-text="item.name"></v-card-title>
                      <v-card-subtitle v-text="item.description"></v-card-subtitle>
                      <v-card-actions> </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="125" tile>
                      <v-img v-if="i == 1 || i % 3 == 1" :src="'https://api.sipymex.cl/' + item.image"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
                <v-card v-if="i == 2 || i % 3 == 2" color="#179db0" dark height="100%">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title class="text-h5" v-text="item.name"></v-card-title>
                      <v-card-subtitle v-text="item.description"></v-card-subtitle>
                      <v-card-actions> </v-card-actions>
                    </div>
                    <v-avatar class="ma-3" size="125" tile>
                      <v-img v-if="i == 2 || i % 3 == 2" :src="'https://api.sipymex.cl/' + item.image"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import auth from "../../logic/auth";
export default {
  inject: ['reload'],
  provide() {
    return {
      reload: this.reload
    }
  },

  data: () => ({

    isRouterAlive: true,
    dialog: false,
    id_customer: 0,
    mount: 0,
    name_equipment: "",
    items: [],
    rut: "",
    items_promo: [],
    selected: [],
    show: false,

    cards: [
      {
        title: "Sunmi T2",
        src: "../../../src/assets/impresora-termica.png",
        flex: 4,
        color: "#0e73b1",
      },
      {
        title: "Sunmi T2 Lite",
        src: "../../../src/assets/sunmit-2-lite.png",
        flex: 4,
        color: "#e9501c",
      },
      {
        color: "#179db0",
        title: "Sunmi T2 mini",
        src: "../../../src/assets/sunmit-2-mini.png",
        flex: 4,
      },
    ],


  }),

  watch: {
    dialog(val) {
      if (!val) return;
    },
  },
  mounted() {
    this.getEquipments();
    this.getEquipmentsPromo();
    this.getIdUserByRut();
    window.onbeforeunload = false;
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    getIdUserByRut() {
      this.rut = auth.getUserRut();
      let request = {
        rut: this.rut,
      };
      axios.post("/customer-by-rut", request).then((response) => {
        console.log("imprime el id por el rut " + response.data.id);
        this.id_customer = response.data.id;
      });
    },

    getEquipments() {
      console.log("entra a obtener equipos");
      axios
        .get("/get-equipment-sale")
        .then((response) => {
          this.items = response.data;
          console.log("imprime equipos");
          console.log(this.items);
          console.log(axios.defaults.baseURL);
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
    getEquipmentsPromo() {
      console.log("entra a obtener equipos");
      axios
        .get("/get-equipment-ofert")
        .then((response) => {
          this.items_promo = response.data;
          console.log("imprime equipos");
          console.log(this.items_promo);
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
    payment(item) {
      if (auth.getUserType()) {
        console.log("entra a pagar");
        this.dialog = true;
        let request = {
          rut: this.rut,
          mount: item.price,
          id_equipment: item.id,
          id_customer: this.id_customer,
          name_equipment: item.name
        };
        axios
          .post("/equipment-pay", request)
          .then((response) => {
            console.log("imprime response");
            console.log(response);
            console.log("url");
            if (response.data.url != null && response.data.token != null) {
              console.log("entra a rdireccionar");
              window.location.href =
                response.data.url + "?token=" + response.data.token;
            }
          })
          .catch((err) => {
            console.log(err, "error");
            this.dialog = false;
          });
      } else {
        this.$router.push({ name: "login", params: { to: "comprar" } });
      }
    },

  },
};
</script>